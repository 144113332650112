import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import Confetti from 'react-confetti';
import { FaCheckCircle, FaDownload, FaVideo, FaGoogle, FaMicrosoft, FaGlobe, FaQuestionCircle, FaFileAlt } from 'react-icons/fa';
// import '../css_files/LoadingScreen.css';
import { jwtDecode } from 'jwt-decode';

const ProcessSignUpD2C = () => {
    const [searchParams] = useSearchParams();

    const [isLoading, setIsLoading] = useState(true);
    const [isSuccessful, setIsSuccessful] = useState(false);
    const [error, setError] = useState('');

    const [userEmail, setUserEmail] = useState('');
    const [userName, setUserName] = useState('');
    const [showConfetti, setShowConfetti] = useState(false);

    useEffect(() => {
        const hash = window.location.hash.substr(1);
        const params = new URLSearchParams(hash);
        const idToken = params.get('id_token');

        /* { TESTING PARAMETERS } */
        // setIsSuccessful(true);
        // setIsLoading(false);
        // setUserEmail("rei@cleo-ai.co"); // Adjust according to your token's claims
        // setUserName("Rei");
        // setShowConfetti(true);

        // setTimeout(() => {
        //     setShowConfetti(false);
        // }, 7000);

        if (idToken) {
            try {
                const decodedToken = jwtDecode(idToken);
                setIsSuccessful(true);
                setIsLoading(false);
                setUserEmail(decodedToken.email); // Adjust according to your token's claims
                const name = decodedToken.name || decodedToken.given_name;
                setUserName(name);
                setShowConfetti(true);

                setTimeout(() => {
                    setShowConfetti(false);
                }, 7000);
            } catch (error) {
                console.error('Error decoding ID token:', error);
                setIsLoading(false);
                setError('There was an error decoding your sign-up information. Please contact admin@cleo-ai.co with your name and email.');
            }
        } else {
            setIsLoading(false);
            setError('There was an error completing your sign-up. Please contact admin@cleo-ai.co with your name and email.');
        }
    }, [searchParams]);

    return (
        <div className="bg-white px-6 py-32 lg:px-8">
            {showConfetti && <Confetti />}
            <div className="mx-auto max-w-3xl text-left">
                {!isLoading && isSuccessful ? (
                    <>
                        <div className="max-w-5/6 mx-auto p-6 bg-white rounded-lg shadow-md">
                            <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl text-center mb-6">
                                Welcome to Cleo, {userName}!
                            </h1>
                            <p className="text-xl leading-8 text-gray-700 text-center mb-8">
                                Below are the steps needed to use Cleo. The whole process should take less than 5 minutes.
                            </p>
                            <ul className="list-disc list-inside space-y-6 text-lg text-gray-700">
                                <li className="flex items-start">
                                    <FaDownload className="text-blue-500 mr-2 mt-1" />
                                    <span>
                                        Download the <a href="https://apps.apple.com/us/app/cleo-health/id6473569298" className="text-blue-500 underline" target="_blank" rel="noopener noreferrer">iOS</a> or <a href="https://play.google.com/store/apps/details?id=com.cleohealth.cleoandroid&pcampaignid=web_share" className="text-blue-500 underline" target="_blank" rel="noopener noreferrer">Android</a> version of the "Cleo Health" mobile app.
                                    </span>
                                </li>
                                <li className="flex items-start">
                                    <FaFileAlt className="text-blue-500 mr-2 mt-1" />
                                    <span>
                                        Review our <a href="https://docs.google.com/document/d/1RPcnglow7kA6CRuLh92vjW_1UU6Eb8uElCk4Z3TCXYk/edit?usp=sharing" className="text-blue-500 underline" target="_blank" rel="noopener noreferrer">Cleo Tip Sheet</a> and visit our <a href="https://cleohealth.io/how-to-cleo/" className="text-blue-500 underline" target="_blank" rel="noopener noreferrer">How to Cleo</a> page for detailed guidance.
                                    </span>
                                </li>
                                <li className="flex items-start">
                                    <FaVideo className="text-blue-500 mr-2 mt-1" />
                                    <span>
                                        Watch <a href="https://vimeo.com/944548053" className="text-blue-500 underline" target="_blank" rel="noopener noreferrer">this one-minute video</a> to learn how to sign in and create your first note with Cleo.
                                    </span>
                                </li>
                                <li className="flex items-start">
                                    <FaGlobe className="text-blue-500 mr-2 mt-1" />
                                    <span>
                                        All created documentation is accessible via our web application at <a href="https://app.cleohealth.io" className="text-blue-500 underline" target="_blank" rel="noopener noreferrer">app.cleohealth.io</a>. Most Cleo providers bookmark this URL for convenience.
                                    </span>
                                </li>
                                <li className="flex items-start">
                                    <FaCheckCircle className="text-blue-500 mr-2 mt-1" />
                                    <span>
                                        You will be enrolled in a 50-chart free trial of the Cleo app, at which point we'll reach out to you again to discuss continuing your Cleo subscription.
                                    </span>
                                </li>
                                <li className="flex items-start">
                                    <FaQuestionCircle className="text-blue-500 mr-2 mt-1" />
                                    <span>
                                        You can message any questions or comments about Cleo directly to <a href="mailto:support@cleo-ai.co" className="text-blue-500 underline" target="_blank" rel="noopener noreferrer">support@cleo-ai.co</a>. We are always here!
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </>
                ) : (
                    // Display error message
                    <div>
                        <h2 className="text-xl font-bold text-red-600">Error</h2>
                        <p className="mt-2 text-gray-800">{error}</p>
                    </div>
                )}
            </div>
        </div>
    );


};

export default ProcessSignUpD2C;
