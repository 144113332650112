// MDMSettings.jsx

import React, { useState, useEffect } from 'react';
import { Switch } from '@headlessui/react';
import { useMsal } from '@azure/msal-react';
import { useAuth } from '../contexts/AuthProvider';
import { protectedResources } from '../authConfig';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function MdmSettings({ onSuccessfulSave }) {
  const { instance, accounts } = useMsal();
  const { actorInfo, isLoading: isActorLoading, updateActorInfo } = useAuth();

  const [alwaysGenerateMdm, setAlwaysGenerateMdm] = useState(actorInfo?.always_generate_mdm || false);
  const [alwaysRegenerateChart, setAlwaysRegenerateChart] = useState(actorInfo?.regenerate_chart_every_encounter || false);

  // State for the free-text block
  const [instructions, setInstructions] = useState('');
  const [charCount, setCharCount] = useState(0);
  const maxChars = 800;

  const [isLoading, setIsLoading] = useState(false);

  const handleInputChange = (e) => {
    const input = e.target.value.slice(0, maxChars);
    setInstructions(input);
    setCharCount(input.length);
  };

  // Radio button options for MDM Style
  const mdmStyleOptions = ['Billing-focused', 'Narrative', 'Both'];

  // Descriptions for MDM Styles
  const mdmStyleDescriptions = {
    'Billing-focused': 'Generate an MDM section that satisfies billing requirements.',
    'Narrative': 'Generate a narrative MDM section focusing on clinical reasoning.',
    'Both': 'Include both billing-focused and narrative MDM sections.',
  };

  // State for selected MDM Style
  const [selectedMdmStyle, setSelectedMdmStyle] = useState(null);

  // Toggles
  const [includeSuggestionsInBrackets, setIncludeSuggestionsInBrackets] = useState(false);
  const [onlyIncludeVerbalized, setOnlyIncludeVerbalized] = useState(false);

  // Instruction Mappings
  const MDM_STYLE_INSTRUCTIONS = {
    'Billing-focused': 'Generate an MDM section focusing on billing requirements.',
    'Narrative': 'Generate a narrative MDM section highlighting clinical reasoning.',
    'Both': 'Generate both billing-focused and narrative MDM sections.',
  };

  const TOGGLE_INSTRUCTIONS = {
    includeSuggestionsInBrackets: 'Include suggestions in brackets.',
    onlyIncludeVerbalized: 'Only include information explicitly verbalized by the provider.',
  };

  // State variable for instructions to send
  const [instructionsToSend, setInstructionsToSend] = useState('');

  // Update instructionsToSend whenever any of the components change
  useEffect(() => {
    // Get the selected MDM Style instruction
    const mdmStyleInstruction = selectedMdmStyle
      ? MDM_STYLE_INSTRUCTIONS[selectedMdmStyle]
      : '';

    // Get the toggle instructions
    const toggleInstructions = [];
    if (includeSuggestionsInBrackets) {
      toggleInstructions.push(TOGGLE_INSTRUCTIONS.includeSuggestionsInBrackets);
    }
    if (onlyIncludeVerbalized) {
      toggleInstructions.push(TOGGLE_INSTRUCTIONS.onlyIncludeVerbalized);
    }

    // Combine instructions
    const combinedInstructions = [instructions, mdmStyleInstruction, ...toggleInstructions]
      .filter(Boolean)
      .join(' ');

    setInstructionsToSend(combinedInstructions);
  }, [instructions, selectedMdmStyle, includeSuggestionsInBrackets, onlyIncludeVerbalized]);

  // Helper function to extract free-text instructions
  const extractFreeTextInstructions = (customInstructions) => {
    let remainingInstructions = customInstructions;

    Object.values(MDM_STYLE_INSTRUCTIONS).forEach((instr) => {
      remainingInstructions = remainingInstructions.replace(instr, '');
    });

    Object.values(TOGGLE_INSTRUCTIONS).forEach((instr) => {
      remainingInstructions = remainingInstructions.replace(instr, '');
    });

    return remainingInstructions.trim();
  };

  // Load existing settings from actorInfo
  useEffect(() => {
    if (actorInfo && !isActorLoading) {
      // Load custom instructions
      const customInstructions = actorInfo.custom_instructions_mdm || '';
      const freeTextInstructions = extractFreeTextInstructions(customInstructions);
      setInstructions(freeTextInstructions);
      setCharCount(freeTextInstructions.length);

      // Initialize MDM Style
      const mdmStyle = mdmStyleOptions.find((option) =>
        customInstructions.includes(MDM_STYLE_INSTRUCTIONS[option])
      );
      setSelectedMdmStyle(mdmStyle || null);

      // Initialize Toggles
      setIncludeSuggestionsInBrackets(
        customInstructions.includes(TOGGLE_INSTRUCTIONS.includeSuggestionsInBrackets)
      );
      setOnlyIncludeVerbalized(
        customInstructions.includes(TOGGLE_INSTRUCTIONS.onlyIncludeVerbalized)
      );
    }
  }, [actorInfo, isActorLoading]);

  const updateActor = async (fieldToUpdate, newValue) => {
    try {
      const request = {
        scopes: protectedResources.apiUpdateActorSettings.scopes,
        account: accounts[0],
      };

      const response = await instance.acquireTokenSilent(request);
      const token = response.accessToken;

      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          field: fieldToUpdate,
          value: newValue,
        }),
      };

      await fetch(protectedResources.apiUpdateActorSettings.endpoint, requestOptions);
      updateActorInfo(fieldToUpdate, newValue);
    } catch (error) {
      console.error('Error updating actor:', error);
    }
  };

  const handleSave = async (e) => {
    e.preventDefault();
    if (accounts.length === 0) {
      console.error('No accounts found');
      return;
    }
    setIsLoading(true);
    try {
      await updateActor('custom_instructions_mdm', instructionsToSend);
      await updateActor('always_generate_mdm', alwaysGenerateMdm);
      await updateActor('regenerate_chart_every_encounter', alwaysRegenerateChart);

      // Reload settings after save
      const customInstructions = instructionsToSend;
      const freeTextInstructions = extractFreeTextInstructions(customInstructions);
      setInstructions(freeTextInstructions);
      setCharCount(freeTextInstructions.length);

      // Initialize MDM Style
      const mdmStyle = mdmStyleOptions.find((option) =>
        customInstructions.includes(MDM_STYLE_INSTRUCTIONS[option])
      );
      setSelectedMdmStyle(mdmStyle || null);

      // Initialize Toggles
      setIncludeSuggestionsInBrackets(
        customInstructions.includes(TOGGLE_INSTRUCTIONS.includeSuggestionsInBrackets)
      );
      setOnlyIncludeVerbalized(
        customInstructions.includes(TOGGLE_INSTRUCTIONS.onlyIncludeVerbalized)
      );
    } catch (error) {
      console.error('Error updating actor:', error);
    }
    setIsLoading(false);
  };

  if (isActorLoading) {
    return (
      <div className="flex justify-center items-center h-64">
        Loading...
      </div>
    );
  }

  return (
    <>
      <div className="flex items-center justify-between mt-8 mb-6">
        <h1 className="text-3xl font-bold leading-tight tracking-tight text-gray-900">
          MDM Configuration
        </h1>
        <button
          onClick={handleSave}
          disabled={isLoading}
          className={classNames(
            'inline-flex items-center rounded-md px-4 py-2 text-sm font-semibold shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-600',
            isLoading
              ? 'bg-gray-400 text-gray-700 cursor-not-allowed'
              : 'bg-indigo-800 text-white hover:bg-indigo-900'
          )}
        >
          {isLoading ? 'Saving...' : 'Save'}
        </button>
      </div>
      
      <div className="bg-white shadow-lg ring-1 ring-gray-900/5 sm:rounded-xl p-6">
        <h2 className="text-lg font-semibold text-gray-900 mb-4">Chart Structure Toggles</h2>
        {/* Always Generate MDM toggle */}
        <div className="p-6 bg-white shadow-md ring-gray-900/5 rounded-lg border-t-2 border-gray-100">

  {/* Always Generate MDM */}
  <div className="flex items-center mb-6">
    <Switch
      checked={alwaysGenerateMdm}
      onChange={() => setAlwaysGenerateMdm(!alwaysGenerateMdm)}
      className={classNames(
        alwaysGenerateMdm ? 'bg-indigo-600' : 'bg-gray-200',
        'relative inline-flex h-6 w-11 items-center rounded-full'
      )}
    >
      <span
        className={classNames(
          alwaysGenerateMdm ? 'translate-x-6' : 'translate-x-1',
          'inline-block h-4 w-4 transform rounded-full bg-white transition-transform'
        )}
      />
    </Switch>
    <div className="ml-3">
      <p className="text-md font-medium text-gray-900">Always Generate MDM</p>
      <p className="text-sm text-gray-500">
        Automatically generate an MDM after every encounter.
      </p>
    </div>
  </div>

  {/* Always Regenerate Chart on Re-evaluation */}
  <div className="flex items-center">
    <Switch
      checked={alwaysRegenerateChart}
      onChange={() => setAlwaysRegenerateChart(!alwaysRegenerateChart)}
      className={classNames(
        alwaysRegenerateChart ? 'bg-indigo-600' : 'bg-gray-200',
        'relative inline-flex h-6 w-11 items-center rounded-full'
      )}
    >
      <span
        className={classNames(
          alwaysRegenerateChart ? 'translate-x-6' : 'translate-x-1',
          'inline-block h-4 w-4 transform rounded-full bg-white transition-transform'
        )}
      />
    </Switch>
    <div className="ml-3">
      <p className="text-md font-medium text-gray-900">Always Regenerate Chart</p>
      <p className="text-sm text-gray-500">
        Cleo will avoid timestamped re-evaluations and generate a fresh chart every time.
      </p>
    </div>
  </div>
</div>


        {/* Free-Text Block Section */}
        <div className="mt-6">
          <h2 className="text-base font-semibold leading-7 text-gray-900">
              Coach Cleo Health
          </h2>
          <p className="text-sm text-gray-500 mb-2">
            Give Cleo specific instructions for constructing your MDM.
          </p>
          <div className="mt-2">
            <div className="relative">
              <div className="overflow-hidden rounded-lg shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-indigo-600">
                <textarea
                  rows={5}
                  value={instructions}
                  onChange={handleInputChange}
                  className="block w-full resize-none border-0 bg-transparent py-1.5 pr-24 text-gray-900 placeholder-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                  placeholder="Enter custom instructions..."
                />
                <div className="absolute bottom-0 right-0 p-2 flex items-center">
                  <span className="text-sm text-gray-500 mr-2">
                    {charCount}/{maxChars}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>


        {/* Toggles Section */}
        <h2 className="text-lg font-semibold text-gray-900 mt-8 mb-4">AI Involvement</h2>
        <div className="space-y-6">
          {/* Include Suggestions in Brackets */}
          <div className="flex items-start">
            <Switch
              checked={includeSuggestionsInBrackets}
              onChange={setIncludeSuggestionsInBrackets}
              className={classNames(
                includeSuggestionsInBrackets ? 'bg-indigo-600' : 'bg-gray-200',
                'relative inline-flex h-6 w-11 items-center rounded-full'
              )}
            >
              <span
                className={classNames(
                  includeSuggestionsInBrackets ? 'translate-x-6' : 'translate-x-1',
                  'inline-block h-4 w-4 transform rounded-full bg-white transition-transform'
                )}
              />
            </Switch>
            <div className="ml-3">
              <p className="text-sm font-medium text-gray-900">
                Include suggestions in brackets
              </p>
              <p className="text-sm text-gray-500">
                Show AI-generated suggestions within brackets.
              </p>
            </div>
          </div>

          {/* Only Include Verbalized */}
          <div className="flex items-start">
            <Switch
              checked={onlyIncludeVerbalized}
              onChange={setOnlyIncludeVerbalized}
              className={classNames(
                onlyIncludeVerbalized ? 'bg-indigo-600' : 'bg-gray-200',
                'relative inline-flex h-6 w-11 items-center rounded-full'
              )}
            >
              <span
                className={classNames(
                  onlyIncludeVerbalized ? 'translate-x-6' : 'translate-x-1',
                  'inline-block h-4 w-4 transform rounded-full bg-white transition-transform'
                )}
              />
            </Switch>
            <div className="ml-3">
              <p className="text-sm font-medium text-gray-900">
                Only include verbalized
              </p>
              <p className="text-sm text-gray-500">
                Include only the information explicitly stated by the provider.
              </p>
            </div>
          </div>
        </div>





        {/* MDM Style Section */}
        <h2 className="text-lg font-semibold text-gray-900 mt-8 mb-4">MDM Style</h2>
        <div className="space-y-4">
          {mdmStyleOptions.map((option) => (
            <div
              key={option}
              className={classNames(
                'relative border rounded-lg p-4 flex cursor-pointer focus:outline-none',
                selectedMdmStyle === option
                  ? 'bg-indigo-50 border-indigo-200'
                  : 'border-gray-200'
              )}
              onClick={() => {
                if (selectedMdmStyle === option) {
                  setSelectedMdmStyle(null);
                } else {
                  setSelectedMdmStyle(option);
                }
              }}
            >
              <span
                className={classNames(
                  selectedMdmStyle === option
                    ? 'bg-indigo-600 border-transparent'
                    : 'bg-white border-gray-300',
                  'h-4 w-4 mt-0.5 cursor-pointer rounded-full border flex items-center justify-center'
                )}
                aria-hidden="true"
              >
                {selectedMdmStyle === option && (
                  <span className="rounded-full bg-white w-1.5 h-1.5" />
                )}
              </span>
              <div className="ml-3 flex flex-col">
                <span className="block text-sm font-medium text-gray-900">{option}</span>
                <span className="block text-sm text-gray-500">
                  {mdmStyleDescriptions[option]}
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
